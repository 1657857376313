import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import afro from '../media/afro.jpg'
import shumic from '../media/shumic2.jpg'
import plywood from '../media/Plywood.jpg'



const Slider = () => {
  return (
    <Carousel className='eny'>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={afro}
        alt="First slide"
      />
      
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={plywood}
        alt="Second slide"
      />

      
    </Carousel.Item>
    <Carousel.Item>
      <img
        className="d-block w-100"
        src={shumic}
        alt="Third slide"
      />

      
    </Carousel.Item>
  </Carousel>
    
  )
}

export default Slider