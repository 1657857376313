import React from 'react'
import './About.css'
import afro from '../../media/product5.png'
// import ply from '../../media/texture.png'
import logo3 from '../../media/logo.png'




const About = () => {
  return (
    <div className='content-2' >
       
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffdf6c" fill-opacity="1"
        d="M0,256L48,240C96,224,192,192,288,176C384,160,480,
        160,576,154.7C672,149,768,139,864,133.3C960,128,1056,128,1152
        ,128C1248,128,1344,128,1392,128L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0
        ,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path></svg>

      <div id="about" className="tequila">
      <img  className='logo2' src={logo3} alt="23" />

            <div className='text'>
                <h1 style={{fontSize: '60px', color: "#202020"}}>ABOUT</h1>
                <p className='text'>
                <span style={{fontSize: "20px", fontWeight: "700", backgroundColor: '#ffdf6c'}}>Composition</span>
                <br /><br />
                Plywood totally made of “Okoume” veneers (Aucouméa klaineana). <br />
                Panguaneta exotic plywood meets the programs for forest sustainable development. <br /><br />

                <span style={{fontSize: "20px", fontWeight: "700" , backgroundColor: '#ffdf6c'}}>Features</span> <br /><br />
                It is FSC mark certified, it guarantees the legal origin of raw materialand the correct forest management. <br />
                Great durability in high moisture and saltiness condition.
                Great dimensional stability and high mechanical properties.
                </p>

            </div>

            <div>
              
            <img className='afro ' src={afro} alt="" />
            {/* <img className='afro' src={ply} alt="" /> */}

            </div>
      </div>


    </div>
  )
}

export default About