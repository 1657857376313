import React from 'react'
import './Sizes.css'
// import afro2 from '../../media/afro.jpg'
import Slider from '../Slider'


const Sizes = () => {
  return (
    <div className='content-3'>
        <div>
            <h1 style={{fontSize: '60px', color: "#202020"}}>SIZES</h1> 

        <div className="table">

            
                <table class="GeneratedTable">
                <thead>
                    <tr>
                    <th>THICNES</th>
                    <th>WIDTH</th>
                    <th>HEIGHT</th>
                    <th>PCS/PALLET</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>4</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>260</td>
                    </tr>
                    <tr>
                    <td>6</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>175</td>
                    </tr>
                    <tr>
                    <td>8</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>90</td>
                    </tr>
                    <tr>
                    <td>10</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>75</td>
                    </tr>
                    <tr>
                    <td>12</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>60</td>
                    </tr>
                    <tr>
                    <td>15</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>55</td>
                    </tr>
                    <tr>
                    <td>18</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>50</td>
                    </tr>
                    <tr>
                    <td>21</td>
                    <td>1220</td>
                    <td>2440</td>
                    <td>45</td>
                    </tr>
                </tbody>
                </table>


        </div>

        <div className='fotja'>  
                <h2> ON STOCK </h2>
                <Slider/> 
        </div>


        
            

        </div>    

        

    </div>
  )
}

export default Sizes