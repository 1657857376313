import './App.css';
import Home from './components/home/Home';
import About from './components/about/About';
import { Navbar } from './components/navbar/Navbar';
import Sizes from './components/Sizes/Sizes';
import Contact from './components/contact/Contact';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Home/>
      <About/>
      <Sizes/>
      <Contact/>
    </div>
  );
}

export default App;
