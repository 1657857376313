import React from 'react'
import './Navbar.css'
import logo from '../../media/icona.png'


export const Navbar = () => {
  let url="#about";
  let url2="#contact";
  let url3 = "#";
  return (
    <div className='nav'>
       <a href={url3}> <img className='logo' src={logo} alt="logo" /></a>
        <ul className='bar'>
            <li> <a href={url3}> Home</a></li>
            <li><a href={url}> About</a></li>
            <li><a href={url2}> Contact</a></li>

        </ul>
    </div>
  )
}
