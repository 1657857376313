import React from 'react'
import './Home.css'
import logo2 from '../../media/logo2.png'


const Home = () => {
  return (
    <div className='header '>


        
        <div className='content'>
        <img className='foto2 img-fluid animated zoomIn' src={logo2} alt="" />

        <h1 style={{color:"#202020"}}>HIGH QUALITY PLYWOOD SUPPLIER</h1>
        <h1 style={{backgroundColor:"#202020"}}>100% </h1>
        <h2 style={{backgroundColor:"#202020"}}>Okume Plywood</h2>     
        <p className='' style={{color:"#202020"}}>Okoume, pronounced oh-kuh-mey, plywood is made from the wood of the Okoume tree
            <br />
            It is sometimes called Okoume Mahogany and has a pinkish-brown or pale red hue
            <br />
            Okoume has a uniform texture and the grain is straight to barely wavy that looks interlocked and attractive
        </p>

        <a href="#contact"><input className="button-19" type="button" value="CONTACT" /></a>

        </div>

        
    
    </div>

    
  )
}

export default Home