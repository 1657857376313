import React from 'react'
import './Contact.css'
import { FaPhoneSquareAlt } from 'react-icons/fa';
import {ImLocation2} from 'react-icons/im';
import {AiFillMail} from 'react-icons/ai';
import logo2 from '../../media/logobardh.png'




const Contact = () => {
  return (
    <div id='contact' className='footer'>

           <div className="info">
            <div className="phone">
                    <h2><FaPhoneSquareAlt style={{color: "#ffdf6c"}}/>. (+383) 45 661 456  </h2>
                    <h2  >   (+381) 63 1627 357  </h2>
                </div>

                <h2>
                <ImLocation2 style={{color: "#ffdf6c"}}/>
                .Pristina, Kosovo &
                Presevo, Serbia
                </h2>


                <h2> <AiFillMail style={{color: "#ffdf6c"}}/>. enyflex.ks@gmail.com</h2>

           </div>

            <div class="footer-right col-md-4 col-sm-6">
            <img  className='logo2' src={logo2} alt="" />

            <p class="menu">
            <a href="#"> Home</a> 
            <a href="#about"> About</a> 
            <a href="#contact"> Contact</a> 
            </p>
            <p class="name"> ENYFLEX &copy; 2020</p>
        </div>



    </div>
  )
}

export default Contact